<template>
  <section
    class="chat-suggested-actions"
    v-if="showSuggestions && !sentSuggestion"
  >
    <vs-divider color="#2786e5">
      {{ lang.attentionRequests.suggestions[languageSelected] }}
      <strong>{{ activeChatUser.botName }}</strong>
    </vs-divider>
    <vs-chip
      class="chat-suggested-actions__suggestion"
      :color="chipColor"
      v-for="suggestion in suggestionMessages"
      :key="suggestion"
    >
      <vs-avatar
        icon="edit"
        :color="actionColor"
        @click="editSuggestion(suggestion)"
      />
      <span @click="sendSuggestion(suggestion)" class="text">
        {{ suggestion }}
      </span>
    </vs-chip>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import BotMakerService from '../../../../services/botMaker.service'

export default {
  name: 'ChatSuggestedActions',
  data() {
    return {
      suggestionMessages: [],
      showSuggestions: false,
      botResponse: null
    }
  },
  props: {
    suggestions: Array,
    chipColor: {
      type: String,
      default: 'primary'
    },
    actionColor: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'activeChatUser',
      'sentSuggestion'
    ])
  },
  methods: {
    ...mapActions(['sendTextMessage']),
    ...mapMutations(['SET_SUGGESTED_CHAT_MESSAGE']),
    editSuggestion(suggestion) {
      this.SET_SUGGESTED_CHAT_MESSAGE(suggestion)
    },
    async sendSuggestion(suggestion) {
      try {
        this.$vs.loading()
        // enviar mensaje simple
        const simpleMessage = {
          bot: this.activeChatUser.bot,
          senderId: this.activeChatUser.sender,
          input: suggestion
        }
        await this.sendTextMessage(simpleMessage)
      } catch (error) {
        console.error(error)
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.attentionRequests.chatForm.bucketError[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    getSuggestionMessages() {
      if (this.suggestions && this.suggestions.length) {
        this.suggestions.forEach(s => {
          this.suggestionMessages.push(s)
        })
      }
    },
    async checkSuggestionsVisibility() {
      this.$vs.loading()
      let botPassiveChat = false
      try {
        if (!this.botResponse) {
          this.botResponse = await BotMakerService.getBot(
            this.activeChatUser.bot
          )
        }
        botPassiveChat = this.botResponse.data.passiveChat
      } catch (error) {
        this.$log.error(error)
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected]
        })
      } finally {
        this.$vs.loading.close()
      }
      // bot shows suggestions, taken by me, has suggestions
      return !!(
        botPassiveChat &&
        this.activeChatUser.using &&
        this.activeChatUser.using._id &&
        this.activeChatUser.using._id === this.$store.state.session.user.id &&
        this.suggestions &&
        this.suggestions.length
      )
    }
  },
  async mounted() {
    this.showSuggestions = await this.checkSuggestionsVisibility()
    if (this.showSuggestions) this.getSuggestionMessages()
  }
}
</script>

<style lang="scss" scoped>
.chat-suggested-actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;

  .vs-divider {
    margin: 14px 0;
  }

  &__suggestion {
    .text {
      cursor: pointer;
    }
  }
}
</style>
